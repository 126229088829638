import { thunkActionCreator } from "store/redux-store";
import { userAPI } from "api";
import { USER_SET_SYSTEM, USER_SET_SYSTEMS, USER_SET_SYSTEMS_BY_GROUPS, USER_SET_USER, USER_SET_LOGIN_EVENTS } from "constants/redux";
import { SystemDto, SystemPageDto, UserLoginEventsDto } from "api/dto";
import { SystemGroupsPageDto } from "api/dto/SystemGroupsPageDto";

export const setSystems = (payload: SystemPageDto) => ({
  type: USER_SET_SYSTEMS,
  payload,
});

export const setSystemsByGroups = (payload: SystemGroupsPageDto) => ({
  type: USER_SET_SYSTEMS_BY_GROUPS,
  payload,
});

export const setSystem = (payload: SystemDto) => ({
  type: USER_SET_SYSTEM,
  payload,
});

export const setUser = (payload: Profile) => ({
  type: USER_SET_USER,
  payload,
});

export const setLoginEvents = (payload: UserLoginEventsDto) => ({
  type: USER_SET_LOGIN_EVENTS,
  payload,
});

export const fetchProfile = thunkActionCreator<void, Profile>(
  userAPI.getProfile,
  setUser
);

export const fetchSystems = thunkActionCreator<void, SystemPageDto>(
  userAPI.getSystems,
  setSystems
);

export const fetchSystemsByGroups = thunkActionCreator<void, SystemGroupsPageDto>(
  userAPI.getSystemsByGroups,
  setSystemsByGroups
);

export const modifyProfile = thunkActionCreator<Pick<Profile, 'firstName' | 'lastName' | 'patrName' | 'phone'>, null>(
  userAPI.updateProfile,
);

export const modifyEmail = thunkActionCreator<string, null>(
  userAPI.updateEmail,
);

export const modifyPassword = thunkActionCreator<{ password: string, oldPassword: string }, null>(
  userAPI.updatePassword,
);

export const fetchLoginEvents = thunkActionCreator<void, UserLoginEventsDto>(
  userAPI.getLoginEvents,
  setLoginEvents
);
