import { Header } from "common/components/header";
import { Grid } from "common/components/ui/grid";
import { Typography } from "common/components/ui/typography";
import styles from "./group.module.scss";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import {useNavigate, useParams} from "react-router-dom";
import { Page404 } from "pages/public-pages";
import { useCallback, useEffect } from "react";
import { fetchGroup, modifyGroup } from "services/app/actions/adminActions";
import { Icon } from "common/components/ui/icon";
import { Backward } from "common/icons";
import { useNotifications } from "common/components/notifications";
import { GroupForm, GroupFormInstance } from "common/forms/group-form";

export const Group = () => {
  const { id: groupId } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { info, error } = useNotifications();

  const group = useAppSelector(({ admin: { group } }) => group );
  const loading = useAppSelector(({ common: { loading } }) => loading );

  useEffect(() => {
    void dispatch(fetchGroup(Number(groupId)));
  }, [dispatch, groupId]);

  const onUpdateSystem = useCallback((formInstance: GroupFormInstance) => {
    dispatch(modifyGroup({ ...formInstance, id: Number(groupId) }))
    .then(() => {
      info('Группа успешно изменена');
        
      navigate('/admin/groups');
    })
    .catch((err: any) => {
      error(`Ошибка изменения группы: ${err}`);
    });
  }, [dispatch, groupId, navigate, info, error]);

  const onCancel = useCallback(() => {
    navigate('/admin/groups');
  }, [navigate]);

  if(!loading && (!groupId || !group.id)) return <Page404 />;

  return (
    <>
      <Header>
        <div className={styles.header}>
          <Icon component={Backward} onClick={() => navigate('/admin')} />
          <Typography variant="body4" weight="500">
            {group.name || ''}
          </Typography>
        </div>
      </Header>
      <Grid container spacing={2}>
        <Grid item xs={12} className={styles.page}>
          <div className={styles.pageContent}>
            <GroupForm defaultValues={group} onSubmit={onUpdateSystem} submitButtonText="Сохранить изменения" onCancel={onCancel} />
          </div>
        </Grid>
      </Grid>
    </>
  );
};