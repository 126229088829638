import {useCallback, useEffect, useMemo} from "react";
import { Button } from "common/components/ui/button";
import { Grid } from "common/components/ui/grid";
import { Input } from "common/components/ui/input";
import { InputController } from "common/components/ui/input-controller";
import { Typography } from "common/components/ui/typography";
import { useForm } from "react-hook-form";
import styles from "./system-form.module.scss";
import { SystemDto } from "api/dto/SystemDto";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { fetchGroups } from "services/app/actions/adminActions";
import ReactSelect from "react-select";
import { SystemGroupDto } from "api/dto/SystemGroupDto";

export type SystemFormInstance = Pick<SystemDto, 'name' | 'url' | 'logoUrl' | 'kcClient' | 'priority' | 'group'>;

type SystemFormProps = {
  defaultValues?: Partial<SystemFormInstance>
  submitButtonText?: string
  loading?: boolean
  onSubmit(formInstance: SystemFormInstance): void
  onCancel?(): void
};

const initialTableState = {
  page: 0,
  pageSize: 10 * 1000 * 1000,
  sortOrder: '~name'
};

export const SystemForm = ({ defaultValues, submitButtonText = 'Сохранить', onSubmit, onCancel }: SystemFormProps) => {
  const dispatch = useAppDispatch();

  const {
    register,
    control,
    formState: { isDirty },
    handleSubmit,
    reset
  } = useForm<SystemFormInstance>({
    defaultValues,
    mode: "onBlur",
    reValidateMode: "onBlur",
    criteriaMode: "all",
    shouldFocusError: true,
  });
  
  const { items: groups } = useAppSelector(({ admin }) => admin.groups);

  const getOptionMap = useCallback((item?: SystemGroupDto) => {
    if (item) {
      return ({
        label: item.name,
        value: item.id,
      });
    }

    return {
      label: 'Не выбрано',
      value: null,
    };
  }, []);

  const groupsSelectList = useMemo(() => {
    const nonGroup = getOptionMap();

    const parsedGroups = groups.map((currentMappingGroup) => ({
      label: currentMappingGroup.name,
      value: currentMappingGroup.id,
    }));

    return [nonGroup, ...parsedGroups];
  }, [groups, getOptionMap]);

  const onSubmitPrepare = useCallback((data: SystemFormInstance) => {
    onSubmit({
      ...data,
      priority: data.priority || null,
      group: data.group || null,
    });
  }, [onSubmit]);

  useEffect(() => {
    dispatch(fetchGroups({
      sorting: initialTableState.sortOrder,
      pageNumber: initialTableState.page,
      pageSize: initialTableState.pageSize
    }));
  }, [dispatch]);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmitPrepare)}>
      <div className={styles.grid}>
        <Grid container spacing={2}>
          <Grid item>
            <Button disabled={!isDirty} type={isDirty ? 'submit' : undefined} >
              {submitButtonText}
            </Button>
          </Grid>
          <Grid item>
            <Button type="reset" variant="outlined" onClick={() => onCancel?.() || reset()}>
              Отмена
            </Button>
          </Grid>
        </Grid>
      </div>
      <InputController
        {...{
          control,
          register,
          name: "name",
          rules: {
            required: { value: true, message: 'Обязательное поле' },
          },
          render: (props: any) => (
            <Input
              label="Название системы"
              className={styles.grid}
              {...props}
            />
          ),
        }}
      />
      <InputController
        {...{
          control,
          register,
          name: "kcClient",
          rules: {
            required: { value: true, message: 'Обязательное поле' },
          },
          render: (props: any) => (
            <Input
              label="Идентификатор системы в Keycloak"
              className={styles.grid}
              {...props}
            />
          ),
        }}
      />
      <InputController
        {...{
          control,
          register,
          name: "url",
          rules: {
            required: { value: true, message: 'Обязательное поле' },
          },
          render: (props: any) => (
            <Input
              label="URL системы"
              className={styles.grid}
              {...props}
            />
          ),
        }}
      />
      <InputController
        {...{
          control,
          register,
          name: "group",
          rules: {
            required: false,
          },
          render: (props: any) => {
            return (
              <Input
                label="Группа системы"
                className={styles.grid}
                input={(
                  <ReactSelect
                    options={groupsSelectList}
                    value={getOptionMap(props.value)}
                    onChange={(option) => {
                      const item = groups.find((currentFindingItem) => currentFindingItem.id === option?.value);

                      if (!option?.value) {
                        props.onChange({
                          target: {
                            value: null,
                          }
                        });
                      }

                      props.onChange({
                        target: {
                          value: item,
                        }
                      });
                    }}
                  />
                )}
              />
            );
          },
        }}
      />
      <InputController
        {...{
          control,
          register,
          name: "logoUrl",
          rules: {
            required: false,
          },
          render: (props: any) => (
            <Input className={styles.grid} label="URL логотипа" {...props} />
          ),
        }}
      />
      <InputController
        {...{
          control,
          register,
          name: "priority",
          rules: {
            required: false,
          },
          render: (props: any) => (
            <Input
              label="Приоритет системы"
              className={styles.grid}
              {...props}
            />
          ),
        }}
      />
      <Typography variant="body1" color={"#76767A"}>
        Допустимый формат файла: png, jpg. Размер файла 150 х 150 px.
      </Typography>
    </form>
  );
};
