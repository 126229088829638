import { Footer } from "common/components/footer";
import { Header } from "common/components/header";
import { Grid } from "common/components/ui/grid";
import { Typography } from "common/components/ui/typography";
import Logo from "common/images/logo-small.svg";
import styles from "./login-events.module.scss";
import {useEffect, useMemo} from "react";
import {Link, useNavigate} from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { Icon } from "common/components/ui/icon";
import {Backward} from "common/icons";
import {useAppDispatch, useAppSelector} from "hooks/hooks";
import { fetchLoginEvents } from "services/app/actions/userActions";
import {SSO_ADMIN_ROLE} from "../../../helpers/constants";
import { PageFooter } from "../../../common/components/ui/pagefooter/page-footer";
import { LoginEvent } from '../../../common/components/login-event';

export const LoginEvents = () => {
  const navigate = useNavigate();

  const loginEvents = useAppSelector(({ user }) => user.loginEvents);

  const { keycloak } = useKeycloak();
  const dispatch = useAppDispatch();
  const hasAdminRole = keycloak.tokenParsed?.realm_access?.roles.includes(SSO_ADMIN_ROLE);

  useEffect(() => {
    (async () => {
      await dispatch(fetchLoginEvents());
    })();
  }, [dispatch]);

  const loginEventsElement = useMemo(() => (
    loginEvents.map((currentLoginEventProps) => (
      <LoginEvent
        {...currentLoginEventProps}
        title={!currentLoginEventProps.error ? 'Успешный вход - ' : 'Неуспешный вход - '}
      />
    ))
  ), [loginEvents]);

  return (
    <>
      <Header>
        <Grid container spacing={2}>
          <Grid item>
            <Link to={hasAdminRole ? "/admin" : "/"}>
              <img src={Logo} alt="logo iri" />
            </Link>
          </Grid>
          <Grid item className={styles.header}>
            <Icon component={Backward} onClick={() => navigate(-1)} />
            <Typography variant="body4" weight="500">
              История входов
            </Typography>
          </Grid>
        </Grid>
      </Header>
      <Grid container spacing={2} direction="column">
        {loginEventsElement}
      </Grid>
      <Footer>
        <PageFooter />
      </Footer>
    </>
  );
};
