import { Typography } from 'common/components/ui/typography';
import React, { PropsWithChildren } from 'react';

import styles from './group.module.scss';
import { Divider } from 'common/components/ui/divider';

interface IGroupProps {
    title: string;
    isHideDivider?: boolean;
}

export const Group = (props: PropsWithChildren<IGroupProps>) => {
    return (
        <div className={styles.group}>
            <div className={styles.title}>
                <div>
                    <Typography variant="body4" weight="500">{props.title}</Typography>
                </div>
                {!props.isHideDivider && (
                    <div className={styles.wrapDivider}>
                        <Divider className={styles.divider} />
                    </div>
                )}
            </div>
            <div className={styles.body}>
                {props.children}
            </div>
        </div>
    );
};
