import React, {useCallback, useEffect} from "react";
import { Button } from "common/components/ui/button";
import { Grid } from "common/components/ui/grid";
import { Input } from "common/components/ui/input";
import { InputController } from "common/components/ui/input-controller";
import { Typography } from "common/components/ui/typography";
import { useForm } from "react-hook-form";
import styles from "./group-form.module.scss";
import { SystemGroupDto } from "api/dto/SystemGroupDto";

export type GroupFormInstance = Pick<SystemGroupDto, 'name' | 'description' | 'priority'>;

type SystemFormProps = {
  defaultValues?: Partial<GroupFormInstance>
  submitButtonText?: string
  loading?: boolean
  onSubmit(formInstance: GroupFormInstance): void
  onCancel?(): void
};

export const GroupForm = ({ defaultValues, submitButtonText = 'Сохранить', onSubmit, onCancel }: SystemFormProps) => {
  const {
    register,
    control,
    formState: { isDirty },
    handleSubmit,
    reset
  } = useForm<GroupFormInstance>({
    defaultValues,
    mode: "onBlur",
    reValidateMode: "onBlur",
    criteriaMode: "all",
    shouldFocusError: true,
  });

  const onSubmitPrepare = useCallback((data: SystemGroupDto) => {
    onSubmit({
      ...data,
      priority: data.priority || null,
    });
  }, [onSubmit]);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmitPrepare)}>
      <div className={styles.grid}>
        <Grid container spacing={2}>
          <Grid item>
            <Button disabled={!isDirty} type={isDirty ? 'submit' : undefined} >
              {submitButtonText}
            </Button>
          </Grid>
          <Grid item>
            <Button type="reset" variant="outlined" onClick={() => onCancel?.() || reset()}>
              Отмена
            </Button>
          </Grid>
        </Grid>
      </div>
      <InputController
        {...{
          control,
          register,
          name: "name",
          rules: {
            required: { value: true, message: 'Обязательное поле' },
          },
          render: (props: any) => (
            <Input
              label="Название группы"
              className={styles.grid}
              {...props}
            />
          ),
        }}
      />
      <InputController
        {...{
          control,
          register,
          name: "description",
          rules: {
            required: { value: true, message: 'Обязательное поле' },
          },
          render: (props: any) => (
            <Input
              label="Описание группы"
              className={styles.grid}
              {...props}
            />
          ),
        }}
      />
      <InputController
        {...{
          control,
          register,
          name: "priority",
          rules: {
            required: false,
          },
          render: (props: any) => (
            <Input
              label="Приоритет"
              className={styles.grid}
              {...props}
            />
          ),
        }}
      />
      <Typography variant="body1" color={"#76767A"}>
        Допустимый формат файла: png, jpg. Размер файла 150 х 150 px.
      </Typography>
    </form>
  );
};
