import React, { FC, useMemo } from "react";

import moment from "moment";

import { Grid } from "common/components/ui/grid";
import { Typography } from "common/components/ui/typography";

import { UserLoginEventDto } from "api/dto";

type TLoginEventProps = UserLoginEventDto & {
    title?: string;
};

export const LoginEvent: FC<TLoginEventProps> = (props) => {
    const date = useMemo(() => (
        moment(props.date).format('HH:mm DD.MM.YYYY')
    ), [props.date]);

    return (
        <Grid item>
            <Typography variant="body3" component="div" weight="400" color={props.error ? "#ff2e56" : "#000"}>
                {props.title}{date} ({props.ip})
            </Typography>
        </Grid>
    );
};
