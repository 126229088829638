export const AUTH = "AUTH";
export const STATUS_SET_STATUS = "STATUS/SET-STATUS";
export const USER_SET_USER = "USER/SET-USER";
export const USER_SET_SYSTEMS = "USER/SET-SYSTEMS";
export const USER_SET_SYSTEMS_BY_GROUPS = "USER/SET-SYSTEMS-BY-GROUPS";
export const USER_SET_AGREEMENTS = "USER/SET-AGREEMENTS";
export const USER_SET_SYSTEM = "USER/SET-SYSTEM";
export const USER_SET_LOGIN_EVENTS = "USER/SET-USER-LOGIN-EVENTS";

export const ADMIN_SET_ADMIN = "ADMIN/SET-ADMIN";
export const ADMIN_SET_SYSTEMS = "ADMIN/SET-SYSTEMS";
export const ADMIN_SET_SYSTEM = "ADMIN/SET-SYSTEM";
export const ADMIN_SET_AGREEMENTS = "ADMIN/SET-AGREEMENTS";
export const ADMIN_SET_AGREEMENT = "ADMIN/SET-AGREEMENT";
export const ADMIN_SET_USER_AGREEMENTS = "ADMIN/SET-USER-AGREEMENT";

export const COMMON_SET_LOADING = 'COMMON/SET-LOADING';
export const COMMON_SET_ERROR = 'COMMON/SET-ERROR';

export const ADMIN_SET_GROUPS = "ADMIN/SET-GROUPS";
export const ADMIN_SET_GROUP = "ADMIN/SET-GROUP";
