import { Header } from "common/components/header";
import { Grid } from "common/components/ui/grid";
import { Typography } from "common/components/ui/typography";
import { Footer } from "common/components/footer";
import styles from "./systems.module.scss";
import Logo from "common/images/logo.svg";
import {useCallback, useEffect, useMemo} from "react";
import { useKeycloak } from "@react-keycloak/web";
import { fetchLoginEvents, fetchSystemsByGroups } from "services/app/actions/userActions";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { SSO_ADMIN_ROLE } from "helpers/constants";
import { Link } from "react-router-dom";
import { noLogo, administrationLogo } from "../../../constants/misc";
import {PageFooter} from "../../../common/components/ui/pagefooter/page-footer";
import { LoginEvent } from "common/components/login-event";
import { Group } from "./components";
import { SystemDto } from "api/dto";
import { COMMON_GROUP_TITLE } from "./constants";

export const Systems = () => {
  const dispatch = useAppDispatch();
  const { keycloak, initialized } = useKeycloak();
  const systemsByGroups = useAppSelector(({ user }) => user.systemsByGroups || []);
  const loginEvents = useAppSelector(({ user }) => user.loginEvents);
  const hasAdminRole = keycloak.tokenParsed?.realm_access?.roles.includes(SSO_ADMIN_ROLE);

  useEffect(() => {
    if(!initialized) return;
    (async () => {
      dispatch(fetchSystemsByGroups());
      await dispatch(fetchLoginEvents());
    })();
  }, [initialized, dispatch]);

  const notGroupedSystems = useMemo(() => {
    return systemsByGroups.find((currentSystemGroup) => (
      !currentSystemGroup.group
    ));
  }, [systemsByGroups]);

  const groupedSystems = useMemo(() => {
    return systemsByGroups.filter((currentSystemGroup) => (
      Boolean(currentSystemGroup.group)
    ));
  }, [systemsByGroups]);

  const loginEventsElement = useMemo(() => {
    if (!loginEvents || !loginEvents.length) {
      return null;
    }

    const lastLoginEvent = loginEvents[0];

    return (
      <LoginEvent 
        {...lastLoginEvent}
        title={!lastLoginEvent.error ? 'Последний успешный вход - ' : 'Предыдущий вход был неуспешен - '}
      />
    );
  }, [loginEvents]);

  const mapSystemFn = useCallback((item: SystemDto) => (
    <a key={item.id} className={styles.systemCard} href={item.url} >
      <div className={styles.systemCover} style={{ backgroundImage: item.logoUrl ? `url(${item.logoUrl})` : `url(${noLogo})` }} />
      <Typography variant="body3" weight="400">
        {item.name}
      </Typography>
    </a>
  ), []);

  const linkToAdmin = useMemo(() => (
    hasAdminRole && (
      <Link to={'/admin'} className={styles.systemCard}>
        <div className={styles.systemCover} style={{ backgroundImage: `url(${administrationLogo})` }} />
        <Typography variant="body3" weight="400">Администрирование</Typography>
      </Link>
    )
  ), [hasAdminRole]);

  return (
    <>
      <Header>
        <Grid container spacing={2}>
          <Grid item>
            <img src={Logo} alt="logo iri" />
          </Grid>
        </Grid>
      </Header>
      <Grid container spacing={2} direction="column">
        <Grid container item alignItems="center" direction="column" className={styles.containerEvents}>
          {loginEventsElement}
        </Grid>
        <Grid item xs={12} className={styles.page}>
          <div className={styles.systemsWrapper}>
            {notGroupedSystems ? (
              <Group title={COMMON_GROUP_TITLE} isHideDivider>
                {linkToAdmin}
                {notGroupedSystems.systems.map(mapSystemFn)}
              </Group>
            ) : (
              <Group title={COMMON_GROUP_TITLE} isHideDivider>
                {linkToAdmin}
              </Group>
            )}
            {groupedSystems.map(({ group, systems }) => (
              <Group key={group.id} title={group.name}>
                {systems.map(mapSystemFn)}
              </Group>
            ))}
            </div>
        </Grid>
      </Grid>
      <Footer>
        <PageFooter />
      </Footer>
    </>
  );
};
